import jennyWillsProfile from 'assets/chat-panel-preview-profiles/jenny-wills-profile.png';
import johnSmithProfile from 'assets/chat-panel-preview-profiles/john-smith-profile.png';
import kimYongProfile from 'assets/chat-panel-preview-profiles/kim-yong-profile.png';
import liaStoneProfile from 'assets/chat-panel-preview-profiles/lia-stone-profile.png';
import markClarkProfile from 'assets/chat-panel-preview-profiles/mark-clark-profile.png';
import marthaJulesProfile from 'assets/chat-panel-preview-profiles/martha-jules-profile.png';
import willBuckProfile from 'assets/chat-panel-preview-profiles/will-buck-profile.png';

import {
  SOCIAL_ID,
  CHAT_ID,
  PEOPLE_ID,
  IFRAME_ID,
  IMAGE_V2_ID,
  CHANNEL_SELECT_ID,
  MULTIPLE_CHOICE_ID,
  PLAYLIST_ID,
  QUEST_ID,
  SCHEDULE_ID,
  TIPS_ID,
  TWITCH_CHAT_ID,
  LEADERBOARD_ID,
  SHOPIFY_ID,
  FORTNITE_DUOS_LEADERBOARD_ID,
  FORTNITE_DUOS_MATCH_ID,
  FORTNITE_PLAY_BY_PLAY_ID,
  FORTNITE_SINGLES_LEADERBOARD_ID,
  FORTNITE_SINGLES_MATCH_ID,
  FORTNITE_TEAMS_LEADERBOARD_ID,
  PLAY_BY_PLAY_ID,
  PUBG_MATCH_ID,
  SHOPIFY_BLOCK_PANEL_ID,
} from './constants';
import { DEFAULT_SHOPIFY } from 'components/page-blocks/Shopify/utils';

interface PanelBaseInfo {
  iconName: string,
  panelName: string,
  panelType: string,
}

export type PANEL_INFO_TYPE<T> = PanelBaseInfo & T;

const mockPanelData = {
  [CHANNEL_SELECT_ID]: {
    _id: 'preview',
    automatic: 'ChannelSelect',
    behavior: {
      kind: 'manual',
      pages: [
        {
          _id: '0',
          data: {
            channelSelectActiveImageUrl: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/6109b28d5bb7a700a64db033.png',
          },
        },
        {
          _id: '1',
          data: {
            channelSelectActiveImageUrl: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/6109b2b12c592200a50ff0aa.png',
          },
        },
        {
          _id: '2',
          data: {
            channelSelectActiveImageUrl: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/6109b2a71ba6f000a61906e7.png',
          },
        },
        {
          _id: '3',
          data: {
            channelSelectActiveImageUrl: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/6109b2985bb7a700a64db034.png',
          },
        },
        {
          _id: '4',
          data: {
            channelSelectActiveImageUrl: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/6109b2985bb7a700a64db034.png',
          },
        },
      ],
    },
    iconName: 'newspaper',
    panelName: 'Channels',
    panelType: CHANNEL_SELECT_ID,
  },
  [CHAT_ID]: {
    chat: [
      {
        image: jennyWillsProfile,
        name: 'Jenny Wills',
        text: 'Yes, yes, yes !!!',
        time: 1612203720000,
      },
      {
        image: jennyWillsProfile,
        name: 'Jenny Wills',
        text: 'Yes, yes, yes !!!',
        time: 1612203720000,
      },
      {
        image: liaStoneProfile,
        name: 'Lia Stone',
        text: 'Hey guys this is wonderfull!',
        time: 1612203660000,
      },
      {
        image: johnSmithProfile,
        name: 'John Smith',
        text: 'Wow this livestream is incredible *.*',
        time: 1612203600000,
      },
      {
        image: markClarkProfile,
        name: 'Mark Clark',
        text: 'love that song! one of my favorites of all time. Even better to see it live <3',
        time: 1612203600000,
      },
      {
        image: marthaJulesProfile,
        name: 'Martha Jules',
        text: 'I’m so stoked for this guys, i can wait to see all the comunity reunited again!',
        time: 1612203480000,
      },
      {
        image: kimYongProfile,
        name: 'Kim Yong',
        text: 'I never thought livestreams were this good! My lord!',
        time: 1612203120000,
      },
      {
        image: willBuckProfile,
        name: 'Will Buck',
        text: 'Hey guys this is amazing!',
        time: 'Just now',
      },
      {
        image: jennyWillsProfile,
        name: 'Jenny Wills',
        text: 'Yes, yes, yes !!!',
        time: 'Just now',
      },
    ],
    iconName: 'chat',
    panelName: 'Chat',
    panelType: CHAT_ID,
  },
  [IFRAME_ID]: {
    iconName: 'link',
    panelName: '',
    panelType: IFRAME_ID,
    url: 'https://www.maestro.io/',
  },
  [IMAGE_V2_ID]: {
    iconName: 'pictures',
    imageList: [
      {
        arrayId: '1',
        imageUrl: 'https://static.gcp.maestro.io/media/5c08126958dfae005af67972/5c086a4f430aa40069f6f1f6.png',
      },
      {
        arrayId: '2',
        imageUrl: 'https://static.gcp.maestro.io/media/5c08126958dfae005af67972/5c087265ce8b1b005b98e58d.png',
      },
      {
        arrayId: '3',
        imageUrl: 'https://static.gcp.maestro.io/media/5ef11af77b34f9002c0a0404/5ef11af77b34f9002c0a040e.png',
      },
    ],
    panelName: 'Upcoming Wallpapers',
    panelType: IMAGE_V2_ID,
    title: 'Upcoming Wallpaper',
    view: {
      type: 'grid',
    },
  },
  [LEADERBOARD_ID]: {
    iconName: 'bar-chart',
    panelName: 'Leaderboard',
    panelType: LEADERBOARD_ID,
  },
  [MULTIPLE_CHOICE_ID]: {
    iconName: 'check',
    panelName: 'Multiple Choice',
    panelType: MULTIPLE_CHOICE_ID,
    previewData: [
      {
        _id: '5',
        collection: 'polls',
        created: 1633032779226,
        data: {
          kind: 'four_option_trivia',
          name: 'Where is the lowest elevation in the US?',
          option1: 'Death Valley',
          option2: 'Navassa Island',
          option3: 'Britton Hill',
          option4: 'Fort Reno',
          question: 'Where is the lowest elevation in the US?',
          showNumbers: false,
          title: 'Where is the lowest elevation in the US?',
        },
        lastModified: 1633032779226,
        siteId: '607ee3c2619057002d4f1784',
        slug: 'poll-61561a4bbc3f1228cf4aae2b-generated',
        type: 'poll',
      },
      {
        _id: '6',
        collection: 'polls',
        created: 1635271275421,
        data: {
          kind: 'two_option_prediction',
          name: 'What is the generation that was born in 2000?',
          option1: 'Team X',
          option1Image: 'https://static.gcp.maestro.io/media/607ee3c2619057002d4f1784/6178705d7fb5ab00a72d2274.png',
          option2: 'Team Y',
          option2Image: 'https://static.gcp.maestro.io/media/607ee3c2619057002d4f1784/6178707edfc96b00a64d95fe.png',
          question: 'What is the generation that was born in 2000?',
          showNumbers: false,
          status: {
            kind: 'open',
          },
          title: 'What is the generation that was born in 2000?',
        },
        lastModified: 1635280490431,
        siteId: '607ee3c2619057002d4f1784',
        slug: 'poll-6178426a9220a8d2c83a6d9b-generated',
        type: 'poll',
      },
      {
        _id: '7',
        collection: 'polls',
        created: 1629482088549,
        data: {
          kind: 'two_option_poll',
          name: 'What kind of Overlays are more usefull',
          option1: 'Option 1',
          option2: 'Option 2',
          question: 'What kind of Overlays are more usefull?',
          showNumbers: false,
          status: {
            kind: 'open',
          },
          title: 'What kind of Overlays are more usefull',
        },
        lastModified: 1629482088549,
        siteId: '607ee3c2619057002d4f1784',
        slug: 'poll-611fec68fffc09182fe58c87-generated',
        type: 'poll',
      },
    ],
  },
  [PEOPLE_ID]: {
    iconName: 'user',
    id: '5c086b760ece11060fef7488',
    panelName: 'Jennifer Lee - Musician',
    panelType: PEOPLE_ID,
    people: [
      {
        id: '5c086b2d8eef42bdadaca3b8',
        personBio: ' As TOKiMONSTA, California producer Jennifer Lee’s charted a fascinating career over the past seven years, and Lune Rouge is her exhilarating next step. The third proper long-player from TOKiMONSTA is both a logical progression of her sound and a surprising left turn from an artist that rarely ceases to surprise. A smoky, patient, and altogether lovely collection, Lune Rouge mixes modern sensibilities with old-school sounds to make for a heady brew of moonlit music.',
        personImageUrl: 'https://static.maestro.io/media/5c08126958dfae005af67972/5c086ad8430aa40069f6f1f7.jpg',
        personLinks: [
          {
            linkIconName: 'twitter',
            linkText: 'Twitter',
            linkUrl: 'https://twitter.com/tokimonsta',
          },
          {
            linkIconName: 'globe',
            linkText: 'Website',
            linkUrl: ' https://tokimonsta.com/',
          },
          {
            linkIconName: 'instagram',
            linkText: 'Instagram',
            linkUrl: 'https://www.instagram.com/tokimonsta',
          },
        ],
        personName: 'tokimonsta',
        personTitle: 'Musician',
      },
    ],
  },
  [PLAYLIST_ID]: {
    iconName: 'list-bullet',
    panelName: 'Playlist',
    panelType: PLAYLIST_ID,
    previewData: [
      {
        _id: '1',
        created: 1601093051137,
        durationSeconds: 213,
        modified: 1601093051137,
        thumbnail: 'https://i.ytimg.com/vi/dQw4w9WgXcQ/sddefault.jpg',
        title: 'Rick Astley - Never Gonna Give You Up (Video)',
      },
      {
        _id: '2',
        created: 1601093081161,
        durationSeconds: 295,
        modified: 1601093081161,
        thumbnail: 'https://i.ytimg.com/vi/l5aZJBLAu1E/sddefault.jpg',
        title: 'The Weather Girls - It"s Raining Men (Video)',
      },
      {
        _id: '3',
        created: 1602658133417,
        durationSeconds: 279,
        modified: 1602658133417,
        thumbnail: 'https://i.ytimg.com/vi/E8gmARGvPlI/sddefault.jpg',
        title: 'Wham! - Last Christmas (Official Video)',
      },
      {
        _id: '4',
        created: 1608861616559,
        durationSeconds: 229,
        modified: 1608861616559,
        thumbnail: 'https://i.ytimg.com/vi/iPUmE-tne5U/sddefault.jpg',
        title: 'Katrina & The Waves - Walking On Sunshine (Official Video)',
      },
    ],
  },
  [QUEST_ID]: {
    iconName: 'award',
    panelName: 'Quest',
    panelType: QUEST_ID,
    quests: [
      {
        data: {
          _id: '60b93a54be2f7a00a5a90ce1',
          actions: [
            {
              arrayId: 'NQUcAQgIR',
              body: 'Watch for 60 minutes',
              kind: 'watch:any:video',
              valueRequired: 5,
            },
            {
              arrayId: 'feHay0Y7a',
              body: 'Get 6 predictions right',
              kind: 'answer:predictions:any',
              valueRequired: 6,
            },
            {
              arrayId: 'RbbpqWGpH',
              body: 'Visit our sponsor website',
              kind: 'answer:predictions:any',
              valueRequired: 10,
            },
          ],
          claimedMessage: '',
          created: 1622751827953,
          description: 'Description goes here',
          modified: 1624648791560,
          rewardDescription: '25 COINS',
          rewardType: 'prize',
          siteId: '607ee3c2619057002d4f1784',
          title: 'Complete the Challenges and get the rewards!',
        },
        progress: {
          actions: {
            feHay0Y7a: {
              progress: 0,
            },
            NQUcAQgIR: {
              completed: true,
              progress: 5,
            },
            RbbpqWGpH: {
              progress: 0,
            },
          },
        },
      },
      {
        data: {
          _id: '60b938f6be2f7a00a5a90ce0',
          claimedMessage: 'Claimed message',
          created: 1622751477916,
          description: '',
          modified: 1624647016659,
          rewardDescription: '2 TICKETS TO GRAND FINALS',
          rewardType: 'prize',
          siteId: '607ee3c2619057002d4f1784',
          title: 'Quest with Impossible Action',
          webhookId: '',
          webhookType: '',
        },
      },
      {
        data: {
          _id: '60b93a54be2f7a00a5a90ce1',
          actions: [
            {
              arrayId: 'NQUcAQgIR',
              body: 'Watch for 60 minutes',
              kind: 'watch:any:video',
              valueRequired: 5,
            },
            {
              arrayId: 'feHay0Y7a',
              body: 'Get 6 predictions right',
              kind: 'answer:predictions:any',
              valueRequired: 6,
            },
            {
              arrayId: 'RbbpqWGpH',
              body: 'Visit our sponsor website',
              kind: 'answer:predictions:any',
              valueRequired: 10,
            },
          ],
          claimedMessage: '',
          created: 1622751827953,
          description: 'Description goes here',
          modified: 1624648791560,
          rewardDescription: '25 COINS',
          rewardType: 'prize',
          siteId: '607ee3c2619057002d4f1784',
          title: 'Complete the Challenges and get the rewards!',
        },
        progress: {
          actions: {
            feHay0Y7a: {
              progress: 0,
            },
            NQUcAQgIR: {
              completed: true,
              progress: 5,
            },
            RbbpqWGpH: {
              progress: 0,
            },
          },
          completed: false,
        },
      },
    ],
  },
  [SCHEDULE_ID]: {
    iconName: 'calendar',
    panelName: 'Schedule',
    panelType: SCHEDULE_ID,
    scheduleTypeRenderer: {
      manualEvents: [
        {
          endTime: 'Jan 6 2019 11:30:00 GMT-0800 (PST)',
          kind: 'event',
          location: 'Dedede Hall',
          moreInfo: '',
          name: 'Welcome Ceremony',
          reference: 'none',
          startTime: 'Jan 6 2019 10:30:00 GMT-0800 (PST)',
        },
        {
          endTime: 'Jan 6 2019 12:30:00 GMT-0800 (PST)',
          kind: 'event',
          location: 'Keanu Theater',
          moreInfo: '',
          name: 'Pre Show',
          reference: 'none',
          startTime: 'Jan 6 2019 12:00:00 GMT-0800 (PST)',
        },
        {
          endTime: 'Jan 7 2019 11:00:00 GMT-0800 (PST)',
          kind: 'event',
          location: 'Keanu Theater',
          moreInfo: '',
          name: 'Main Event',
          reference: 'none',
          startTime: 'Jan 6 2019 13:00:00 GMT-0800 (PST)',
        },
        {
          endTime: 'Jan 7 2019 12:45:00 GMT-0800 (PST)',
          kind: 'event',
          location: 'Dedede Hall',
          moreInfo: '',
          name: 'Recap & Highlights',
          reference: 'none',
          startTime: 'Jan 7 2019 11:30:00 GMT-0800 (PST)',
        },
      ],
      scheduleType: 'manual',
    },
  },
  [SHOPIFY_ID]: {
    collection: {
      products: {
        edges: [
          {
            node: {
              availableForSale: false,
              id: '1',
              images: {
                edges: [
                  {
                    node: {
                      url: 'https://static.gcp.maestro.io/media/607ee3c2619057002d4f1784/612d193827270f00a64bb754.jpg',
                    },
                  },
                ],
              },
              title: 'Maestro if the 9 Iron',
              variants: {
                edges: [
                  {
                    node: {
                      price: 19.99,
                    },
                  },
                ],
              },
            },
          },
          {
            node: {
              availableForSale: true,
              id: '2',
              images: {
                edges: [
                  {
                    node: {
                      url: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/60da6aebcb8f5400a55983f4.png',
                    },
                  },
                ],
              },
              title: 'Build a Meaningful Relationship with Your Laptop',
              variants: {
                edges: [
                  {
                    node: {
                      price: 19.99,
                    },
                  },
                ],
              },
            },
          },
          {
            node: {
              availableForSale: true,
              id: '3',
              images: {
                edges: [
                  {
                    node: {
                      url: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/60da6ae0332cc400a580b27e.png',
                    },
                  },
                ],
              },
              title: 'Maestro // "Not Your Dad\'s Hat"',
              variants: {
                edges: [
                  {
                    node: {
                      price: 19.99,
                    },
                  },
                ],
              },
            },
          },
          {
            node: {
              availableForSale: true,
              id: '4',
              images: {
                edges: [
                  {
                    node: {
                      url: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/60da6ac571e54400a58ed240.png',
                    },
                  },
                ],
              },
              title: 'Maestro // "Not Your Dad\'s Hat"',
              variants: {
                edges: [
                  {
                    node: {
                      price: 19.99,
                    },
                  },
                ],
              },
            },
          },
          {
            node: {
              availableForSale: true,
              id: '5',
              images: {
                edges: [
                  {
                    node: {
                      url: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/60db4669c47d3300a538c16c.png',
                    },
                  },
                ],
              },
              title: 'Maestro // "Not Your Dad\'s Hat"',
              variants: {
                edges: [
                  {
                    node: {
                      price: 19.99,
                    },
                  },
                ],
              },
            },
          },
          {
            node: {
              availableForSale: true,
              id: '5',
              images: {
                edges: [
                  {
                    node: {
                      url: 'https://static.maestro.io/media/607ee3c2619057002d4f1784/60db46459d54c600a673b906.png',
                    },
                  },
                ],
              },
              title: 'Maestro // "Not Your Dad\'s Hat"',
              variants: {
                edges: [
                  {
                    node: {
                      price: 19.99,
                    },
                  },
                ],
              },
            },
          },
        ],
      },
      title: 'Maestro F/W 2021',
    },
    iconName: 'basket',
    id: '1',
    panelName: 'Merch',
    panelType: SHOPIFY_ID,
  },
  [SOCIAL_ID]: {
    iconName: 'twitter',
    panelName: 'Maestro Twitter',
    panelType: SOCIAL_ID,
    sources: [
      {
        arrayId: 'KlsqzWOE8',
        source: '@maestroio',
      },
    ],
    title: 'Twitter',
  },
  [TIPS_ID]: {
    iconName: 'tips',
    panelName: 'Tips',
    panelType: TIPS_ID,
  },
  [TWITCH_CHAT_ID]: {
    channel: 'monstercat',
    iconName: 'twitch',
    panelName: 'Twitch',
    panelType: TWITCH_CHAT_ID,
  },
  [SHOPIFY_BLOCK_PANEL_ID]: {
    iconName: 'shopify',
    panelType: SHOPIFY_BLOCK_PANEL_ID,
    blockData: DEFAULT_SHOPIFY,
  },
  [FORTNITE_DUOS_LEADERBOARD_ID]: {
    iconName: 'bar-chart',
    panelName: 'Fornite Duos Leaderboard',
    panelType: FORTNITE_DUOS_LEADERBOARD_ID,
  },
  [FORTNITE_DUOS_MATCH_ID]: {
    iconName: 'gamer',
    panelName: 'Fornite Duos Match',
    panelType: FORTNITE_DUOS_MATCH_ID,
  },
  [FORTNITE_PLAY_BY_PLAY_ID]: {
    iconName: 'gamer',
    panelName: 'Fornite Play by Play',
    panelType: FORTNITE_PLAY_BY_PLAY_ID,
  },
  [FORTNITE_SINGLES_LEADERBOARD_ID]: {
    iconName: 'bar-chart',
    panelName: 'Fornite Singles Leaderboard',
    panelType: FORTNITE_SINGLES_LEADERBOARD_ID,
  },
  [FORTNITE_SINGLES_MATCH_ID]: {
    iconName: 'gamer',
    panelName: 'Fornite Singles Match',
    panelType: FORTNITE_SINGLES_MATCH_ID,
  },
  [FORTNITE_TEAMS_LEADERBOARD_ID]: {
    iconName: 'bar-chart',
    panelName: 'Fornite Teams Leaderboard',
    panelType: FORTNITE_TEAMS_LEADERBOARD_ID,
  },
  [PLAY_BY_PLAY_ID]: {
    iconName: 'gamer',
    panelName: 'Play by Play',
    panelType: PLAY_BY_PLAY_ID,
  },
  [PUBG_MATCH_ID]: {
    iconName: 'gamer',
    panelName: 'Pubg Match',
    panelType: PUBG_MATCH_ID,
  },
};

export default mockPanelData;
