import React from 'react';
import useCalendarEvent from '../use-calendar-event';
import { IEventCardProps } from '../../utils';
import { ContentContainer, Description, PrimaryInfo, SecondaryInfo, TimeContainer, Title, Wrapper, StyledEventDate, FullEventActionsMenu, EditIcon, TrashIcon } from './styles';
import Labels from '../Labels';
import useEditPageBlock from 'hooks/use-page-block';
import EventTime from './DateTime/Time';
import EventImage from './Image';
import { useDispatch, useSelector } from 'react-redux';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import LiveBadge from 'components/ui/LiveBadge';
import { ICalendarEvent } from 'models/ICalendarEvent';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import shortid from 'shortid';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

const FullEventCard: React.FC<IEventCardProps> = ({
  readOnly,
  event,
  onChange,
  onClick,
  showActionsMenu,
  onRemove,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { titleRaw, titleRawV2, image, descriptionRaw, descriptionRawV2 } = event;

  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const useTitleRawDataV2 = Boolean(isRichTextEditorV3Enabled && titleRawV2);
  const title = useTitleRawDataV2 ? titleRawV2 : titleRaw;
  const titleKey = useTitleRawDataV2 ? 'titleRawV2' : 'titleRaw';

  const useDescriptionRawDataV2 = Boolean(isRichTextEditorV3Enabled && descriptionRawV2);
  const description = useDescriptionRawDataV2 ? descriptionRawV2 : descriptionRaw;
  const descriptionKey = useDescriptionRawDataV2 ? 'descriptionRawV2' : 'descriptionRaw';

  const { isLive, labels, startDate, when } = useCalendarEvent(event, readOnly);
  const { handleChange } = useEditPageBlock<keyof ICalendarEvent, ICalendarEvent>({
    item: event,
    onChange: (updatedEvent) => onChange?.(updatedEvent),
  });

  const handleCloseEventSettingsModal = () => {
    dispatch(dismissModal(ModalKinds.calendarBlockEventSettings));
  };

  const handleEditEvent = () => {
    const parsedEvent: ICalendarEvent = {
      ...event,
      categories: (event.categories || []).map(category => ({
        ...category,
        id: shortid.generate(),
        label: {
          ...category.label,
          id: shortid.generate(),
        },
      })),
    };

    dispatch(showModal({
      kind: ModalKinds.calendarBlockEventSettings,
      data: {
        locked: true,
        calendarEvent: parsedEvent,
        onDismiss: handleCloseEventSettingsModal,
        onSave: (updatedEvent) => onChange?.(updatedEvent),
      },
    }));
  };

  const handleRemoveEvent = React.useCallback(() => {
    onRemove?.(event);
  }, [event, onRemove]);

  return (
    <Wrapper {...rest} firstLabelColor={labels[0]?.color} onClick={onClick}>
      {image.show && <EventImage readOnly={readOnly} image={event.image} onChange={handleChange('image')} />}
      {
        showActionsMenu && (
          <FullEventActionsMenu
            admin={false}
            actions={[
              {
                onClick: handleEditEvent,
                stringKey: 'ADMIN_CALENDAR_BLOCK_EDIT_EVENT',
                icon: <EditIcon />,
              },
              {
                onClick: handleRemoveEvent,
                stringKey: 'ADMIN_CALENDAR_BLOCK_DELETE_EVENT',
                icon: <TrashIcon />,
              },
            ]}
            testId="full-event-card-actions-menu"
          />
        )
      }
      <ContentContainer showImage={image.show}>
        <PrimaryInfo>
          <StyledEventDate
            readOnly={readOnly}
            date={event.date}
            startDate={startDate}
            onChange={handleChange('date')}
          />
          <Title
            readOnly={readOnly}
            data={title}
            onChange={handleChange(titleKey)}
            useRawDataV2={useTitleRawDataV2}
            blockTitleKey="ADMIN_LABEL_TITLE"
            maxLines={3}
            maxLength={72}
            toolbarButtons={{
              ...PREDEFINED_TOOLBAR_BUTTONS.titleEditor,
              bold: false,
              fontsize: false,
            }}
            data-testid="calendarEventTitle"
          />
          <Description
            readOnly={readOnly}
            data={description}
            onChange={handleChange(descriptionKey)}
            useRawDataV2={useDescriptionRawDataV2}
            blockTitleKey="ADMIN_LABEL_DESCRIPTION"
            maxLines={3}
            maxLength={250}
            toolbarButtons={{
              ...PREDEFINED_TOOLBAR_BUTTONS.bodyEditor,
              bold: false,
              fontsize: false,
            }}
            data-testid="calendarEventDescription"
          />
        </PrimaryInfo>
        <SecondaryInfo>
          <TimeContainer>
            <EventTime
              readOnly={readOnly}
              date={event.date}
              onChange={handleChange('date')}
              when={when}
            />
            {isLive && readOnly && <LiveBadge.Default />}
          </TimeContainer>
          <Labels labels={labels} />
        </SecondaryInfo>
      </ContentContainer>
    </Wrapper>
  );
};

export default FullEventCard;
