import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRealtimeDocument } from 'hooks';
import { SITE_ID } from 'config';
import { useSelector } from 'react-redux';
import { getOverlays, getOverlayBeingPreviewed, getLastOverlay } from 'services/overlays/selectors';
import { BroadcastOverlay } from 'components/Video/video-content/Overlays';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: 1280px;
  height: 720px;
  background-color: transparent;
  display: block;
`;

const OverlayContainer = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
`;

const OVERLAY_STATUS_TIMER_MS = 2000;

const Overlay = ({ overlay, statusClass }) => {
  // eslint-disable-next-line react/no-danger
  return <div className={`overlay ${statusClass}`} dangerouslySetInnerHTML={{ __html: overlay.contents.html }} />;
};

Overlay.propTypes = {
  overlay: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  statusClass: PropTypes.string.isRequired,
};

const CompositePage = () => {
  const [overlay] = useRealtimeDocument('Overlay', SITE_ID);
  const previewingOverlay = useSelector(getOverlayBeingPreviewed);
  const lastOverlay = useSelector(getLastOverlay);

  const overlayList = useSelector(getOverlays);
  const chooseTargetOverlay = () => previewingOverlay || lastOverlay;

  const [targetOverlay, setTargetOverlay] = useState(chooseTargetOverlay());

  useEffect(() => {
    setTargetOverlay(chooseTargetOverlay());
  }, [previewingOverlay, overlayList, lastOverlay]);

  const [activeOverlay, setActiveOverlay] = useState(null);
  const [overlayStatusClass, setOverlayStatusClass] = useState('enter');

  if (overlay && !activeOverlay) {
    setActiveOverlay(overlay);
    setOverlayStatusClass('enter');
  }

  useEffect(() => {
    if (overlay && activeOverlay) {
      setTimeout(() => {
        setOverlayStatusClass('');
      }, OVERLAY_STATUS_TIMER_MS);
    }

    if (!overlay && activeOverlay) {
      setOverlayStatusClass('exit');
      setTimeout(() => {
        setActiveOverlay(null);
        setOverlayStatusClass('enter');
      }, OVERLAY_STATUS_TIMER_MS);
    }
  }, [overlay, activeOverlay]);

  return (
    <Container>
      <OverlayContainer>
        {activeOverlay && <Overlay overlay={activeOverlay} statusClass={overlayStatusClass} />}
        {targetOverlay && <BroadcastOverlay overlay={targetOverlay} />}
      </OverlayContainer>
    </Container>
  );
};

export default CompositePage;
