import React from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import BlockSettings from './BlockSettings';
import { StyledButton, SubTitle, StyledQuickEditOverlay, Title, StyledCTAEditorModal, Label, BackgroundImage, RelativeWrapper } from './styles';
import { DEFAULT_TEXT_BANNER, ITextBanner } from './TextBanner.utils';
import usePageBlock from 'hooks/use-page-block';
import { isMobileLayout } from 'services/device';
import { DynamicTranslationType, useAdminTranslation, useEndUserInlineTextEditorTranslation, useEndUserTranslation } from 'hooks/use-translation';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

export interface ITextBannerItem {
  arrayId: string;
  data: ITextBanner;
  kind: string;
}

interface ITextBannerProps {
  index: number;
  item: ITextBannerItem;
  onEdit: (item: ITextBannerItem) => void;
  onRemove: () => void;
  /**
   * This is needed for localization. The `pageId` is used
   * in conjunction to the block's `arrayId` to uniquely identify it.
   */
  pageId: string;
}

type EditableStringPath = (
  'label.rawData' |
  'label.rawDataV2' |
  'cta.text' |
  'cta.link' |
  'cta.variant' |
  'cta.fontSize' |
  'title.rawData' |
  'title.rawDataV2' |
  'subtitle.rawData' |
  'subtitle.rawDataV2' |
  'cta.behavior' |
  'cta.height.desktop' |
  'cta.height.mobile'
);

const TextBanner: React.FC<ITextBannerProps> = ({
  item,
  onEdit,
  onRemove,
  pageId,
}) => {
  const { t } = useAdminTranslation();
  const [ctaText, setCtaText] = React.useState(item.data.cta.text);
  const isEditing = useSelector(isEditMode);
  const isMobile = useSelector(isMobileLayout);
  const { inlineTextEditorEndUserT } = useEndUserInlineTextEditorTranslation(isEditing);
  const { endUserT } = useEndUserTranslation();
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);

  const shouldShowLocalizedVersion = !isEditing;
  const baseParams = {
    pageId,
    blockId: item.arrayId,
  };

  const localizedCtaText = endUserT([
    DynamicTranslationType.textBannerCta,
    baseParams,
  ]) || ctaText;
  const localizedLabel = inlineTextEditorEndUserT(DynamicTranslationType.textBannerLabel, item.data.label.rawData, baseParams);
  const localizedTitle = inlineTextEditorEndUserT(DynamicTranslationType.textBannerTitle, item.data.title.rawData, baseParams);
  const localizedSubtitle = inlineTextEditorEndUserT(DynamicTranslationType.textBannerSubtitle, item.data.subtitle.rawData, baseParams);

  const handleChangeData = React.useCallback((update: ITextBanner) => {
    onEdit({ ...item, data: update });
  }, [item, onEdit]);

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<EditableStringPath, ITextBanner>({
    item: item.data,
    onChange: handleChangeData,
  });

  const renderLabel = () => {
    if (!item.data.label.show) {
      return null;
    }

    const { rawDataV2 } = item.data.label;
    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && rawDataV2);
    const data = useRawDataV2 ? rawDataV2 : localizedLabel;
    const key = useRawDataV2 ? 'label.rawDataV2' : 'label.rawData' as const;

    return (
      <Label
        blockTitleKey="ADMIN_LABEL"
        onChange={handleChange(key)}
        data={data}
        useRawDataV2={useRawDataV2}
        alignment={item.data.alignment}
        backgroundColor={item.data.background.color}
        data-testid="textLabel"
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
      />
    );
  };

  const renderSubtitle = () => {
    if (!item.data.subtitle.show) {
      return null;
    }

    const { rawDataV2 } = item.data.subtitle;
    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && rawDataV2);
    const data = useRawDataV2 ? rawDataV2 : localizedSubtitle;
    const key = useRawDataV2 ? 'subtitle.rawDataV2' : 'subtitle.rawData' as const;

    return (
      <SubTitle
        blockTitleKey="ADMIN_LABEL_SUBTITLE"
        onChange={handleChange(key)}
        useRawDataV2={useRawDataV2}
        data={data}
        alignment={item.data.alignment}
        data-testid="textSubtitle"
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.bodyEditor}
      />
    );
  };

  const renderTitle = () => {
    const { rawDataV2 } = item.data.title;
    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && rawDataV2);
    const data = useRawDataV2 ? rawDataV2 : localizedTitle;
    const key = useRawDataV2 ? 'title.rawDataV2' : 'title.rawData' as const;

    return (
      <Title
        blockTitleKey="ADMIN_LABEL_TITLE"
        onChange={handleChange(key)}
        data={data}
        useRawDataV2={useRawDataV2}
        alignment={item.data.alignment}
        data-testid="textTitle"
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
        htmlTag="h2"
      />
    );
  };

  const handleCheckCtaText = () => {
    const hasText = Boolean(ctaText.trim().length);
    if (item.data.cta.text === ctaText) {
      return;
    }

    onEdit({
      ...item,
      data: {
        ...item.data,
        cta: {
          ...item.data.cta,
          text: hasText ? ctaText : DEFAULT_TEXT_BANNER(t).cta.text,
        },
      },
    });

    if (!hasText) {
      setCtaText(DEFAULT_TEXT_BANNER(t).cta.text);
    }
  };

  React.useEffect(() => {
    setCtaText(item.data.cta.text);
  }, [item.data.cta.text]);

  const backgroundImage = React.useMemo(() => {
    if (isMobile && item.data.background.image.mobile) {
      return item.data.background.image.mobile;
    }

    if (!isMobile && item.data.background.image.desktop) {
      return item.data.background.image.desktop;
    }

    return null;
  }, [
    isMobile,
    item.data.background.image.mobile,
    item.data.background.image.desktop,
  ]);

  return (
    <StyledQuickEditOverlay
      backgroundColor={item.data.background.color}
      onRemove={onRemove}
      titleKey="LABEL_TEXT_BLOCK"
      onEdit={handleOpenSettings}
      testId={`textBanner-${item.arrayId}`}
    >
      <RelativeWrapper alignment={item.data.alignment}>
        {item.data.background.image.show && backgroundImage && (
          <BackgroundImage
            src={backgroundImage}
            alt="Text Banner Background Image"
          />
        )}
        {renderLabel()}
        {renderTitle()}
        {renderSubtitle()}
        {item.data.cta.show ? (
          <StyledCTAEditorModal
            blockTitleKey="ADMIN_LABEL_CTA"
            modalTitleKey="ADMIN_LABEL_CALL_TO_ACTION_CTA"
            onChangeText={setCtaText}
            cta={{
              ...item.data.cta,
              text: ctaText,
            }}
            onChangeFontSize={handleChange('cta.fontSize')}
            onChangeVariant={handleChange('cta.variant')}
            onChangeBehavior={handleChange('cta.behavior')}
            onChangeDesktopHeight={handleChange('cta.height.desktop')}
            onChangeMobileHeight={handleChange('cta.height.mobile')}
            onModalClose={handleCheckCtaText}
            data-testid="textCTAButtonWrapper"
          >
            {
              text => (
                <StyledButton
                  fontSize={item.data.cta.fontSize}
                  variant={item.data.cta.variant}
                  containerBackgroundColor={item.data.background.color}
                  height={item.data.cta.height}
                  data-testid="textCTAButton"
                >
                  {shouldShowLocalizedVersion ? localizedCtaText : text}
                </StyledButton>
              )
            }
          </StyledCTAEditorModal>
        ) : null}
        <BlockSettings
          isOpen={isSettingsOpen}
          onClose={handleCloseSettings}
          item={item.data}
          onChange={handleChangeData}
        />
      </RelativeWrapper>
    </StyledQuickEditOverlay>
  );
};

export default TextBanner;
