/* eslint-disable no-restricted-imports */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import defaultPerson from 'assets/default-person.svg';
import Gallery from 'components/ui/Gallery';
import Label from 'components/admin2/ui/Label';

const Container = styled.div`
  width: 100%;
  margin-bottom: 23px;
`;

export default class ProfileImageSelector extends React.Component {
  static propTypes = {
    currentImage: PropTypes.string,
    currentProfileImages: PropTypes.arrayOf(PropTypes.shape({
      isAdmin: PropTypes.bool,
      url: PropTypes.string,
    })).isRequired,
    selected: PropTypes.number,
    selectImage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentImage: defaultPerson,
    selected: null,
  };

  render() {
    const {
      currentProfileImages,
      selected,
      selectImage,
      currentImage,
    } = this.props;
    const selectedImage = currentProfileImages[selected];
    const getFilename = url => url.slice(url.lastIndexOf('/')).replace(/\?\w+/g, '');
    const images = currentProfileImages;

    return (
      <Container>
        <Label labelKey="SELECT_A_PROFILE_PICTURE" uppercase />
        <Gallery
          filterAdmin
          highlight={(image) => {
            return selectedImage ?
              selectedImage.url === image.url :
              getFilename(image.url || image.image) === getFilename(currentImage);
          }}
          images={images}
          onSelect={(_, index) => selectImage(index)}
        />
      </Container>
    );
  }
}
