import styled, { css } from 'styled-components';
import {
  ACCENT_PRIMARY,
  ADMIN_BUTTON_BORDER_RADIUS,
  HIGHLIGHT_PRIMARY,
  HIGHLIGHT_TERTIARY,
  SURFACE_1,
  SURFACE_3,
  TEXT_100,
} from 'style/constants';
import { TEXT_P2 } from 'style/mixins';
import { IconSvg } from 'components/ui/Icon';
import { avatarWidth } from '../shared/styles';
import withConfirmation from 'components/core/withConfirmation';

export const UserName = styled.b`
  ${TEXT_P2};
  font-weight: 600;
  color: ${TEXT_100};
  display: inline-block;
  line-height: 20px;

  &[aria-role='button'] {
    user-select: none;
    cursor: pointer;
  }
`;

export const UserAvatarWrapper = styled.div<{ isPrivileged: boolean }>`
  height: ${avatarWidth}px;
  width: ${avatarWidth}px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  display: inline-block;
  font-size: 0;

  &[aria-role='button'] {
    cursor: pointer;
  }

  ${({ isPrivileged }) => (
    isPrivileged &&
      css`
        border: 1px solid ${ACCENT_PRIMARY};
      `
  )}
`;

export const UserAvatar = styled.img`
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;
`;

export const ChatStarIconDiv = styled.div`
  position: absolute;
  background: ${ACCENT_PRIMARY};
  width: 9px;
  border-radius: 50%;
  top: -2px;
  height: 9px;
  display: flex;
  right: -3px;
  justify-content: center;
  align-items: center;
`;

export const ChatStarIcon = styled(IconSvg).attrs(() => ({
  name: 'chatStar',
}))`
  height: 6px;
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserAdminOptions = styled.div`
  position: absolute;
  background: ${SURFACE_1};
  width: calc(100% + 8px);
  left: -4px;
  top: 14px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};
  font-weight: 600;
  color: ${TEXT_100};

  &[aria-hidden='true'] {
    display: none;
  }

  .avatarNameContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    line-height: 22px;

    & ${UserAvatar} {
      margin-right: 8px;
      width: 42px;
      height: 42px;
    }
  }

  button {
    font: inherit;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 9px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};

    &.bgcolor-surface3 {
      background: ${SURFACE_3};
      color: ${HIGHLIGHT_PRIMARY};

      svg > path {
        fill: ${HIGHLIGHT_PRIMARY};
      }
    }

    &.bgcolor-accentPrimary {
      background: ${ACCENT_PRIMARY};
      color: ${HIGHLIGHT_TERTIARY};

      svg > path {
        fill: ${HIGHLIGHT_TERTIARY};
      }
    }

    span {
      display: contents;
    }

    svg {
      margin-right: 10px;
      height: 15px;
      width: auto;
    }
  }
`;

export const ButtonWithConfirmation = withConfirmation(styled.button``);
