import { connect } from 'react-redux';
import { isUserAdmin, logOut, getAccountCreatedDate } from 'services/auth';
import { requestClosePanel } from 'services/custom-panels';
import { getUserName, getUserAvatar, isActiveIcon } from 'services/user-profile';
import { trackPanel } from 'services/insights';
import { getSiteProfileImages } from 'services/app/selectors';
import { trackEvent } from 'services/segment-analytics';
import { AdminActionEvents } from 'services/admin/models';

import ProfilePanel from './view';

const mapStateToProps = state => ({
  activeIcon: isActiveIcon(state),
  createdDate: getAccountCreatedDate(state),
  currentProfileImages: getSiteProfileImages(state),
  isUserAdmin: isUserAdmin(state),
  userAvatar: getUserAvatar(state),
  userName: getUserName(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePanel: () => dispatch(requestClosePanel()),
  onLogout: () => {
    dispatch(requestClosePanel());
    dispatch(trackEvent({ event: AdminActionEvents.ADMIN_LOGOUT, properties: {} }));
    dispatch(logOut());
    ownProps.onLogout?.();
  },
  trackEngage: (doc, extra) => dispatch(trackPanel('engage', doc, extra)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePanel);
