import { getDefaultRichTextEditorData } from 'utils';
import { cloneDeep } from 'lodash';
import { isLightColor } from 'services/themes/utils';
import { injectT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';
import { CTAButtonProps, DEFAULT_CTA_BEHAVIOR, DEFAULT_CTA_HEIGHT_SIZE } from 'components/admin-bridge/CTAEditorModal/utils';
import { getDefaultEditorState, getDefaultHeadingNode, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';

interface ICTA extends CTAButtonProps {
  show: boolean;
}

interface IBackground {
  bleed: boolean;
  desktop: string;
  gradientMask: boolean;
  href: string;
  mobile: string;
}

interface ITextEditor {
  rawData: string;
  rawDataV2?: string;
  show: boolean;
}

export interface IHeroBanner {
  background: IBackground;
  description: ITextEditor;
  primaryCta: ICTA;
  secondaryCta: ICTA;
  title: ITextEditor;
}

export interface IHeroBannerItem {
  arrayId: string;
  data: IHeroBanner;
  kind: string;
}

export interface IHeroBannerProps {
  disableImageBleed: boolean;
  index: number;
  item: IHeroBannerItem;
  onEdit: (item: IHeroBannerItem) => void;
  onRemove: () => void;
}

const IMAGE_DESKTOP_DARK_MODE = 'https://static.gcp.maestro.io/media/607ee3c2619057002d4f1784/6345e2141a396500a71d6820.jpg';
const IMAGE_DESKTOP_LIGHT_MODE = 'https://static.gcp.maestro.io/media/607ee3c2619057002d4f1784/6345e24720e65500a7a9bacb.jpg';
const IMAGE_MOBILE_DARK_MODE = 'https://static.gcp.maestro.io/media/607ee3c2619057002d4f1784/6345e1edf4615f00a8fe56d8.jpg';
const IMAGE_MOBILE_LIGHT_MODE = 'https://static.gcp.maestro.io/media/607ee3c2619057002d4f1784/6345e2275eca1000a83b4cc8.jpg';

// Image examples for bleed on (height 755 for desktop, 675 for mobile)
// const IMAGE_375_755_HEIGHT = 'https://i.ibb.co/Xx62LXm/Frame-16442.png';
// const IMAGE_1920_675_HEIGHT = 'https://i.ibb.co/Wy8T3rx/image-76.png';

// Image examples for bleed off (height 460 for desktop and 590 for mobile)
// const IMAGE_1920_460_HEIGHT = 'https://i.ibb.co/4S7Lvny/image-79.png';
// const IMAGE_375_590_HEIGHT = 'https://i.ibb.co/jfj4M2f/Frame-16440-1.png';

export const DEFAULT_HERO_BANNER = injectT(
  (t) => lazy<IHeroBanner>(
    (): IHeroBanner => ({
      title: {
        rawData: getDefaultRichTextEditorData({
          text: t('ADMIN_HERO_BLOCK_DEFAULT_TITLE'),
          fontSize: 40,
          typographicalEmphasis: 'BOLD',
        }),
        rawDataV2: getDefaultEditorState([
          getDefaultHeadingNode({
            tag: 'h1',
            children: [
              getDefaultTextNode({
                text: t('ADMIN_HERO_BLOCK_DEFAULT_TITLE'),
                fontSize: 40,
                textFormatType: 'bold',
              }),
            ],
          }),
        ]),
        show: true,
      },
      description: {
        rawData: getDefaultRichTextEditorData({
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non diam, aenean non nibh libero, egestas nullam turpis vel. Laoreet arcu faucibus placerat rhoncus, parturient ante sed ante. Placerat malesuada fames sit tincidunt eu. Sagittis, proin diam, porttitor amet in. Blandit vestibulum gravida.',
          fontSize: 16,
        }),
        rawDataV2: getDefaultEditorState([
          getDefaultTextNode({
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non diam, aenean non nibh libero, egestas nullam turpis vel. Laoreet arcu faucibus placerat rhoncus, parturient ante sed ante. Placerat malesuada fames sit tincidunt eu. Sagittis, proin diam, porttitor amet in. Blandit vestibulum gravida.',
            fontSize: 16,
          }),
        ]),
        show: true,
      },
      primaryCta: {
        fontSize: 14,
        show: true,
        variant: 'solid',
        text: t('ADMIN_TEXT_BLOCK_DEFAULT_CTA_TEXT'),
        behavior: DEFAULT_CTA_BEHAVIOR,
        height: {
          desktop: DEFAULT_CTA_HEIGHT_SIZE,
          mobile: DEFAULT_CTA_HEIGHT_SIZE,
        },
      },
      secondaryCta: {
        fontSize: 14,
        show: false,
        variant: 'outline',
        text: t('ADMIN_HERO_BLOCK_DEFAULT_SECONDARY_CTA_TEXT'),
        behavior: DEFAULT_CTA_BEHAVIOR,
        height: {
          desktop: DEFAULT_CTA_HEIGHT_SIZE,
          mobile: DEFAULT_CTA_HEIGHT_SIZE,
        },
      },
      background: {
        href: '',
        bleed: true,
        desktop: '',
        gradientMask: false,
        mobile: '',
      },
    }),
  ),
);

// We use surface1 to determine the background image of the hero banner
export const getDefaultHeroBanner = injectT(
  (t) => (surface1: string): IHeroBanner => {
    const isSurfaceLight = isLightColor(surface1);

    const heroBanner = cloneDeep(DEFAULT_HERO_BANNER(t));
    heroBanner.background.desktop = isSurfaceLight ? IMAGE_DESKTOP_LIGHT_MODE : IMAGE_DESKTOP_DARK_MODE;
    heroBanner.background.mobile = isSurfaceLight ? IMAGE_MOBILE_LIGHT_MODE : IMAGE_MOBILE_DARK_MODE;

    return heroBanner;
  },
);
