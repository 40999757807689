import React, { FC, memo, useCallback, useEffect } from 'react';
import type IState from 'services/state';
import { useDispatch } from 'hooks';
import {
  isLandscape as isLandscapeSelector,
  isMobileLayout as isMobileLayoutSelector,
} from 'services/device/selectors';
import {
  isLivestreamPreviewActive,
  isMaestroStreamMode as isMaestroStreamModeSelector,
  isStreamMethodSelectMode as isStreamMethodSelectModeSelector,
} from 'services/admin';
import {
  setOrientationLocked,
  updateVideo,
} from 'services/user-layout/actions';
import {
  isMobilePanelEmbed,
  isTheaterActive as isTheaterActiveSelector,
  isVideoEmbed as isVideoEmbedSelector,
} from 'services/user-layout/selectors';
import {
  getObjectId,
  getShowMobileChannelSelector,
} from 'services/app/selectors';
import { getStudioGuestModeInviteCode } from 'services/livestream/selectors';
import { shouldRenderChannelGate } from 'services/gate/selectors/common';
import { useSelector } from 'react-redux';
import TranslatedText from 'components/i18n/TranslatedText';
import MobileChannelSelect from 'components/mobile/MobileChannelSelect';
import StreamModeSelect from 'components/StreamModeSelect';
import MaestroStudioGuest from 'components/MaestroStudio/Guest';
import ContentLayout from 'components/regions/ContentLayout';
import ChannelSelect from 'components/regions/ChannelSelect';
import ChannelOfflineContent from 'components/ui/ChannelOfflineContent';
import ChannelOfflineOverlay from 'components/ui/ChannelOfflineGoLiveOverlay';
import { StyledVideo, BasicContainer, ExpandVideo, Wrapper } from './styles';
import { ContentType } from 'models';
import { useCanIPlay } from 'services/caniplay/useCanIPlay';
import { isScheduledAsset, useEpg } from 'services/epg/useEpg';
import {
  usePlaylist,
  usePlaylistOrchestration,
} from 'services/playlist/usePlaylist';
import { PlayerSpot } from 'services/video';
import { useSetShareVideoId } from 'contexts/GlobalVideoContext';
import Skeleton from 'components/ui/Skeleton';
import { getIsOrientationLocked } from 'services/user-layout/selectors/common';

const Channel: FC = memo(() => {
  const dispatch = useDispatch();
  const isOrientationLocked = useSelector(getIsOrientationLocked);
  const hasVideo = useSelector((state: IState) => state.userLayout.wantsVideo);
  const isLandscape = useSelector(isLandscapeSelector);
  const isMaestroStreamMode = useSelector(isMaestroStreamModeSelector);
  const isMobileLayout = useSelector(isMobileLayoutSelector);
  const isMobilePanelEmbedLayout = useSelector(isMobilePanelEmbed);
  const isPreviewingLivestream = useSelector(isLivestreamPreviewActive);
  const isStreamMethodSelectMode = useSelector(
    isStreamMethodSelectModeSelector,
  );
  const isVideoEmbed = useSelector(isVideoEmbedSelector);
  const showMobileChannelSelector = useSelector(getShowMobileChannelSelector);
  const studioInviteCode = useSelector(getStudioGuestModeInviteCode);
  const isTheaterActive = useSelector(isTheaterActiveSelector);
  const isChannelGateOn = useSelector(shouldRenderChannelGate);
  const channelId = useSelector(getObjectId);

  const showChannelSelect = !isTheaterActive && !isChannelGateOn;

  const { epgContent, activateNextAsset, epgLoaded } = useEpg(channelId);

  const isLive = Boolean(epgContent?.live);
  const epgContentId = epgContent?.contentId;
  const epgType = epgContent?.contentType;

  const isScheduledContent = isScheduledAsset(epgContent);

  const videoCanIPlayResult = useCanIPlay(
    epgType === ContentType.playlist ? null : epgContentId,
  );

  const playlist = usePlaylist(
    epgType === ContentType.playlist ? epgContentId : undefined,
  );

  const [playlistCanIPlayResult, playNextPlaylistItem] =
    usePlaylistOrchestration(playlist);

  const canIPlayResult = playlistCanIPlayResult ?? videoCanIPlayResult;

  const toggleVideo = useCallback(() => {
    dispatch(updateVideo(!hasVideo));
  }, [hasVideo]);

  if (isOrientationLocked) {
    dispatch(setOrientationLocked(false));
  }

  useSetShareVideoId(canIPlayResult?.video?._id);

  if (isMobilePanelEmbedLayout) {
    return null;
  }

  if (isStreamMethodSelectMode) {
    return (
      <BasicContainer>
        <StreamModeSelect />
      </BasicContainer>
    );
  }

  if (studioInviteCode) {
    return (
      <BasicContainer>
        <MaestroStudioGuest />
      </BasicContainer>
    );
  }

  const showStreamPreview = isMaestroStreamMode && isPreviewingLivestream;
  const showOfflineContent =
    epgLoaded && !isLive && !isMaestroStreamMode && !isPreviewingLivestream;

  return (
    <ContentLayout>
      <Wrapper>
        {showOfflineContent && (
          <>
            <ChannelOfflineOverlay />
            <ChannelOfflineContent
              video={canIPlayResult?.video}
              onVideoEnd={playNextPlaylistItem}
              loop={playlist === undefined}
            />
          </>
        )}
        {showMobileChannelSelector && <MobileChannelSelect />}
        {showStreamPreview && (
          <StyledVideo isVideoEmbed={false} streamPreviewMode={true} />
        )}
        {(!epgLoaded || (isLive && !canIPlayResult?.video)) && (
          <Skeleton type="video" />
        )}
        {!showStreamPreview && !showOfflineContent && canIPlayResult?.video && (
          <StyledVideo
            scheduledVideoStartTime={isScheduledContent ? epgContent.startTime : null}
            onVideoComplete={isScheduledContent ? activateNextAsset : undefined}
            isVideoEmbed={isVideoEmbed}
            video={canIPlayResult.video}
            spot={PlayerSpot.video}
          />
        )}
        {isMobileLayout && !isLandscape && !hasVideo && (
          <ExpandVideo onClick={toggleVideo}>
            <TranslatedText stringKey="ACTION_EXPAND_VIDEO" />
          </ExpandVideo>
        )}
        {showChannelSelect && <ChannelSelect />}
      </Wrapper>
    </ContentLayout>
  );
});

export default Channel;
