import React, { FC, useCallback, useMemo, SyntheticEvent } from 'react';
import AdminModal from 'components/admin2/ui/AdminModal';
import TranslatedText from 'components/i18n/TranslatedText';
import Icon from 'components/ui/Icon';
import {
  Button,
  Container,
  Footer,
  FormContainer,
  FormField,
  FormLabel,
  FormNameDescSection,
  Header,
  Subtitle,
  UploadZone,
  TagsSelect,
  TagsList,
  BulkListWrapper,
  BulkListTitle,
  BulkList,
} from './styles';
import { useVideoTags } from 'hooks';
import { IVideo, PlayerTypes } from 'models';
import {
  formField,
  useSimpleForm,
  validateNonEmptyString,
  validateStringArray,
  validateUrl,
} from 'hooks/use-simple-form';
import { ModalData } from '../Modal/view';
import { PartiallyPartial } from 'utils/partiallyPartial';
import { useVideoLibraryContext } from 'contexts/VideoLibraryContext';
import { DEFAULT_VIDEO_PLACEHOLDER_ICON } from 'shared/constants';
import { useAdminTranslation } from 'hooks/use-translation';
import { isEqual } from 'lodash';
import { useEmbedCode } from './useEmbedCode';

const fields = {
  name: formField(true, [''], validateNonEmptyString),
  description: formField(false, [''], validateNonEmptyString),
  thumbnail: formField(false, ['', undefined, null], validateUrl),
  tags: formField(false, [[]], validateStringArray),
  url: formField(false, [''], validateUrl),
} as const;

export type VideoMetadataModalV2Props = ModalData<{
  videos: (
    PartiallyPartial<IVideo, 'durationSeconds' | 'siteId' | 'type'> & {
      newId?: string;
    }
  )[];
}>;

export const VideoMetadataModalV2: FC<VideoMetadataModalV2Props> = ({ data, onDismiss }) => {
  const videos = data?.videos || [];
  const { t } = useAdminTranslation();

  const { editVideoMetadata } = useVideoLibraryContext();

  const siteTags = useVideoTags();
  const tagOptions = useMemo(
    () => siteTags.map(
      (tag) => ({
        label: tag,
        value: tag,
      }),
    ),
    [siteTags],
  );
  const isBulk = videos.length > 1;
  const video = !isBulk ? videos[0] : null;

  const { embedCodeRef, generateUrl } = useEmbedCode(
    video?.player === PlayerTypes.iframe
      ? video.url
      : undefined,
  );

  const [formValues, changeHandlers] = useSimpleForm(
    fields,
    {
      name: video?.title || '',
      description: video?.description || '',
      tags: video?.tags || [],
      thumbnail: video?.thumbnail || undefined,
      url: video?.url,
    },
  );

  const setSelectedTags = changeHandlers.tags.rawValue;
  const selectedTags = useMemo(
    () => formValues.tags?.map(
      (tag) => ({
        label: tag,
        value: tag,
      }),
    ),
    [formValues.tags],
  );

  const handleTagsChange = useCallback(
    (selected: typeof tagOptions) =>
      setSelectedTags(selected.map(({ value }) => value)),
    [],
  );

  const handleTagCreation = useCallback(
    (tag: string) =>
      setSelectedTags([tag, ...(formValues.tags ?? [])]),
    [formValues.tags],
  );

  const handleRemoveTag = useCallback(
    (tagId: string) =>
      setSelectedTags(formValues.tags?.filter((tag) => tag !== tagId)),
    [formValues.tags],
  );

  const handleSubmit = useCallback(
    (ev: SyntheticEvent<HTMLFormElement>) => {
      ev.preventDefault();

      // tslint:disable-next-line:forin
      for (const key in fields) {
        const fieldName = key as keyof typeof fields;

        if (fieldName === 'url')
          continue;

        const field = fields[fieldName];
        const formValue = formValues[fieldName];
        const shouldValidate = field.required || !field.emptyValues.some(
          (empty: any) => isEqual(formValue, empty),
        );

        if (shouldValidate && field.validate && !field.validate(formValue)) {
          // tslint:disable-next-line:no-console
          console.log('Error validating form:', fieldName, 'is invalid', formValue);
          return;
        }
      }

      if (isBulk) {
        editVideoMetadata(
          videos.map(
            (v) => ({
              ...v,
              _id: v._id!,
              newId: v.newId,
              description: formValues.description,
              thumbnail: formValues.thumbnail,
              tags: formValues.tags,
            }),
          ),
        )
          .catch((err) => {
            // tslint:disable-next-line:no-console
            console.error('Failure when upserting videos:', err);
          });

        onDismiss();
        return;
      }

      const newVideoData: Parameters<typeof editVideoMetadata>[0][0] = {
        ...videos[0],
        _id: videos[0]._id!,
        newId: videos[0].newId,
        title: formValues.name,
        description: formValues.description,
        thumbnail: formValues.thumbnail,
        tags: formValues.tags,
      };

      (async () => {
        try {
          if (videos[0].player === PlayerTypes.iframe) {
            const url = await generateUrl();

            if (!url)
              throw new Error(`Couldn't generate embed code url`);

            newVideoData.url = url;
          }
          await editVideoMetadata([newVideoData]);
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error('Failure when upserting video:', err);
        }
      })();

      onDismiss();
    },
    [formValues, editVideoMetadata, videos, onDismiss],
  );

  const clearThumbnail = useCallback(
    () => changeHandlers.thumbnail.rawValue(null),
    [changeHandlers.thumbnail],
  );

  return (
    <AdminModal
      maxWidth="864px"
      fixedHeight={true}
      onClose={onDismiss}
    >
      <Container
        onSubmit={handleSubmit}
      >
        <Header>
          <TranslatedText
            stringKey={
              isBulk
                ? 'ADMIN_EDIT_BULK_METADATA'
                : 'ADMIN_EDIT_METADATA'
            }
          />
        </Header>
        <Subtitle>
          <TranslatedText
            stringKey={
              isBulk
                ? 'ADMIN_EDIT_BUL_METADATA_SUBTITLE'
                : 'ADMIN_EDIT_METADATA_SUBTITLE'
            }
          />
        </Subtitle>
        <FormContainer>
          <FormNameDescSection>
            {isBulk ? (
              <>
                <BulkListWrapper>
                  <BulkListTitle>{videos.length} {t('ADMIN_MODAL_VIDEO_METADATA_SELECTED')}</BulkListTitle>
                  <BulkList>
                    {videos.map((videoItem: any) => (
                      <li key={videoItem._id}>{videoItem.title}</li>
                    ))}
                  </BulkList>
                </BulkListWrapper>
              </>
            ) : (
              <>
                <FormLabel>
                  {t('ADMIN_LABEL_NAME')}
                  <FormField
                    required={true}
                    value={formValues.name}
                    onChange={changeHandlers.name.eventHandler}
                  />
                </FormLabel>
                <FormLabel>
                  {
                    videos[0].player === PlayerTypes.iframe
                      ? (
                        <>
                          {t('ADMIN_VIDEO_EDIT_FORM_EMBED_CODE')}
                          <FormField
                            ref={embedCodeRef}
                            defaultValue=""
                            as="textarea"
                            className="embed-code"
                          />
                        </>
                      )
                      : (
                          <>
                            {t('ADMIN_LABEL_URL')}
                            <FormField
                              disabled={true}
                              value={formValues.url || ''}
                              onChange={changeHandlers.url.eventHandler}
                            />
                          </>
                        )
                  }
                </FormLabel>
              </>
            )}
            <FormLabel>
              {t('ADMIN_LABEL_DESCRIPTION')}
              <FormField
                placeholder={t('ADMIN_PLACEHOLDER_ENTER_DESCRIPTION')}
                as="textarea"
                value={formValues.description}
                onChange={changeHandlers.description.eventHandler}
              />
            </FormLabel>
          </FormNameDescSection>
          <div style={{ maxWidth: 320 }}>
            <FormLabel as="div">
              {t('ADMIN_LABEL_THUMBNAIL')}
              <i>{t('ADMIN_LABEL_DIMENSIONS')} (16:9)</i>
              <UploadZone
                onClearImage={formValues.thumbnail ? clearThumbnail : undefined}
                imagePreview={formValues.thumbnail || DEFAULT_VIDEO_PLACEHOLDER_ICON}
                onFileSubmit={changeHandlers.thumbnail.rawValue}
                height={180}
                dimensionRequirements={{
                  exactDimensions: true,
                  exactHeight: true,
                  exactWidth: true,
                  pixelHeight: 360,
                  pixelWidth: 640,
                  verify: true,
                }}
              />
            </FormLabel>
            <FormLabel>
              {t('ADMIN_LABEL_TAGS')}
              <TagsSelect
                controlShouldRenderValue={false}
                isDisabled={formValues.tags?.length! >= 6}
                isMulti={true}
                isSearchable={true}
                onChange={handleTagsChange}
                onCreateOption={handleTagCreation}
                options={tagOptions}
                placeholder={t('ADMIN_TAGS_PLACEHOLDER')}
                value={selectedTags}
                t={t}
              />
              <TagsList>
                {formValues.tags?.map(
                  (tag) => (
                    <li key={tag}>
                      <button
                        type="button"
                        onClick={handleRemoveTag.bind(null, tag)}
                      >
                        <Icon name="cancel-thin" />
                      </button>
                      {tag}
                    </li>
                  ),
                )}
              </TagsList>
            </FormLabel>
          </div>
        </FormContainer>
        <Footer>
          <Button type="submit">
            <TranslatedText stringKey="DONE" />
          </Button>
        </Footer>
      </Container>
    </AdminModal>
  );
};
