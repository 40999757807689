import { rgba } from 'colors';
import { CloseButton } from 'components/ui/Button';
import { HIGHLIGHT_PRIMARY, SURFACE_3, SURFACE_4, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isOpen: boolean; }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  bottom: 0px;
  border-radius: 20px 20px 0px 0px;
  background: ${SURFACE_3};
  box-shadow: 0px -2px 54px 0px rgba(0, 0, 0, 0.90);
  width: 100vw;
  left: 0;

  max-height: 0;
  overflow: hidden;

  ${({ isOpen }) => isOpen && css`
    max-height: calc(100vh - 40px - 48px); // 40px of mobile header + 48px required in design to show the content behind the bottom sheet
    transition: max-height 0.3s ease-in-out;
  `};
`;

export const BackgroundLayer = styled.div`
  position: fixed;
  z-index: 2;
  inset: 0;
  background-color: ${props => rgba(SURFACE_3(props), 0.5)};
`;

export const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background-color: unset;
  z-index: 1;

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
`;


export const Header = styled.header`
  display: flex;
  padding: 26px 20px 20px 20px;
  align-items: center;
  border-bottom: 1px solid ${SURFACE_4};
  min-height: 36px;
  align-items: center;
  justify-content: center;

  &:empty {
    display: none;
  }
`;

export const OverflowContent = styled.div`
  overflow-y: auto;
  padding: 20px;
`;

export const Title = styled.h2`
  all: unset;
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};
`;
