import TranslatedText from 'components/i18n/TranslatedText';
import ExternalLoginButton from 'components/modals/AuthModal/ExternalLoginButton';
import { GATE_AMAZON_BENEFIT_ID } from 'global-ids';
import { t } from 'i18next';
import { DATE_TIME } from 'injection-classes';
import { isEqual } from 'lodash';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserTokenData } from 'services/auth';
import { amazonBenefitGateInfo, getFixedGateData, getChannelAmazonBenefitData, getApiLoading } from 'services/gate';
import { ACCENT_PRIMARY, TEXT_100 } from 'style/constants';
import { mobileOnly, TEXT_H6 } from 'style/mixins';
import styled from 'styled-components';
import { Container, ErrorWrapper, StyledDateAndTime } from './styles';
import GateTitle from './GateTitle';
import GateSubtitle from './GateSubtitle';

const ThirdPartyOauthsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  ${mobileOnly`
    width: 100%;
  `}
`;

export const StyledButton = styled.button`
  ${TEXT_H6};
  background: ${(props) => ACCENT_PRIMARY(props)};
  width: 100%;
  height: 41px;
  border-radius: 3px;
  color: ${TEXT_100};
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 22px;
`;

export const AmazonBenefitErrorWrapper = styled(ErrorWrapper)`
  flex-direction: column;
`;

export const AmazonBenefitGateAccessControlElements =
  ({ loadedFromAdmin = false }:
    { loadedFromAdmin?: boolean}) => {
  const userToken = useSelector(getUserTokenData);
  const amazonBenefitGateData = useSelector(getChannelAmazonBenefitData);
  const amazonBenefitData = useSelector(amazonBenefitGateInfo);
  const loading = useSelector(getApiLoading);
  const isLoggedIn = !!userToken;

  const getErrorMessage = useCallback(() => {
    if(loadedFromAdmin) {
      return null;
    }
    if(isLoggedIn && (amazonBenefitData.hasCheckError || amazonBenefitData.hasRedemptionError)) {
      if (amazonBenefitData.hasCheckError && !amazonBenefitData.message) {
        return <TranslatedText stringKey="ACCESS_GATE_AMAZON_BENEFIT_PURCHASE_ERROR" />;
      }
      if (amazonBenefitData.hasRedemptionError && !amazonBenefitData.message) {
        return <TranslatedText stringKey="ACCESS_GATE_AMAZON_BENEFIT_REDEMPTION_ERROR" />;
      }
      return amazonBenefitData.message;
    }
    return null;
  }, [amazonBenefitData, amazonBenefitGateData]);

  const redirectToPurchaseURL = () => window.location.href = amazonBenefitGateData.amazonBenefitPurchaseUrl;

  return (
    <ThirdPartyOauthsWrapper>
      <AmazonBenefitErrorWrapper>
          <span>{getErrorMessage()}</span>
      </AmazonBenefitErrorWrapper>
      { (!loading && isLoggedIn && userToken?.service !== 'amazon') || !isLoggedIn ? (
        <ExternalLoginButton kind="amazon" signUp={false} key="amazon" />
      ) : null }

      {!loadedFromAdmin && (isLoggedIn && amazonBenefitData.hasCheckError || amazonBenefitData.hasRedemptionError && !loading) ? (
        <StyledButton
          key="amazonBenefitPurchaseURLButton"
          data-testid="amazonBenefitPurchaseURLButton"
          onClick={redirectToPurchaseURL}
        >
          <TranslatedText stringKey="ACCESS_GATE_AMAZON_BENEFIT_PURCHASE_BUTTON" />
        </StyledButton>
      ): null }
    </ThirdPartyOauthsWrapper>
    );
};

export const AmazonBenefitGate: React.FC = () => {
  const { timestamp } = useSelector(getFixedGateData(t), isEqual);

  return (
    <Container id={GATE_AMAZON_BENEFIT_ID} data-testid="login-gate">
      {timestamp && <StyledDateAndTime className={DATE_TIME} timeStamp={timestamp} />}
      <GateTitle />
      <GateSubtitle />
      <AmazonBenefitGateAccessControlElements />
    </Container>
  );
};
