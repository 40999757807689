export interface IEmbedCode {
  url: string;
}

// don't make changes to this script as we use this for validating all uploaded iframe code
export const generateAdditionalScript = (blockId: string) =>  `
<!-- maestro embedded -->
  <script>
    (function() {
      let previousHeight = 0;

      function sendDocumentHeight() {
        const currentHeight = Math.ceil(document.body.scrollHeight);
        if (currentHeight !== previousHeight) {
          previousHeight = currentHeight;
          window.parent.postMessage({ iframeHeight: currentHeight, blockId: "${blockId}", type: "resizeIframe" }, '*');
        }
      }

      document.addEventListener('DOMContentLoaded', () => {
        setInterval(sendDocumentHeight, 50);
      });

      document.body.style.margin = '0';
      document.body.style.padding = '0';
    })();
  </script>
`;

